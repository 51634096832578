import loginPageIconImport from "../assets/login.svg";
import registerPageIconImport from "../assets/register.svg";

export const adminPath = "geomaged-admin";
export const domainName = "magedemam.com";
export const isManualPayment = false;
export const isCouponable = true;
export const isMultiYear = false;
export const isCodes = true;
export const isBtns = true;
export const isLoginTokens = true;
export const profileStatistics = true;

export const enableAutoLogin = false;

export const isFreeTrialAvailable = false;

export const adminPanelColor = "cyan";
export const headerSectionColor = "sky";
export const navbarIconColor = "text-sky-500";
export const navbarDisclosureColor = "sky";

export const progressBarColor = "cyan";
export const loadingBarColor = "cyan";

export const isBunny = false;

export const isPlatformSubscription = false;
export const isTimedManualSubscription = false;

export const isPrepaidSystem = true;
export const prepaidInfoColor = "rose";

export const autoChangeRandomNames = true;

export const navThemeRTL = true;

export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const loginPageColor = "yellow";
export const registerPageColor = "cyan";

export const isAnalytics = false;
export const isAbleChangingPassword = false;

export const isMultiAdmin = true;

export const isSubjectSubscriptionable = false;

export const isAccountCreationRequest = false;

export const showExamStatisticsTable = true;

export const isCategories = true;

export const isRegisterationMiddleName = true;
export const isRegisterationFatherJob = true;
export const isRegisterationGender = true;
export const isUserCoursesSubscriptionTable = true;
export const isAdminToAdminNote = true;

export const isFawry = true;
export const isOtpEnabled = true;
