import React, { useEffect, useState } from "react";
import InputField from "../../../components/form/elements/InputField";
// import RemoteTable from "../../../components/ui/RemoteTable";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import { printFullDate, printFullDateTime } from "../../../utils/time";
import Table from "../../../components/ui/Table";

const UserCoursesSubscriptonsTable = ({ user = { id: 0 } }) => {
    const api = `/api/users/${user["id"]}/profile/courses/paginate`;

    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState([]);
    // const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [sort, setSort] = useState({
        column: {
            sortField: "id",
        },
        sortDirection: "asc",
    });

    // const adminToken = auth.getAdminToken();
    //         const config = auth.getAdminAuthConfig(adminToken);

    //         const toSendData = { ...data };
    //         // toSendData["phone"] = parseInt(data["phone"]);
    //         const { data: response } = await http.post("/api/subscriptions", toSendData, config);

    //         modal.message({
    //             title: "تم تنفيذ طلبك بنجاح",
    //             text: response.message,
    //             button: "مشاهدة نتيجة العملية",
    //             callback: () => {
    //                 window.scrollTo(0, 0);
    //             },
    //         });
    //         setResult(response.result);
    //         setShowResult(true);
    //         setData(initialState);
    //         setIsLoading(false);

    const subscribe = async ({ currentTarget }, id) => {
        setIsLoading(true);
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        const toSendData = {
            phone: user.phone,
            course_id: id,
            to_unsubscribe: currentTarget.value ? 0 : 1,
        };

        try {
            const response = await http.post("/api/subscribe_course", toSendData, config);

            if (!response) {
                throw new Error({ response: { error: "error" } });
            }
            let newData = data.map((row) => {
                if (row.id === id) {
                    return {
                        ...row,
                        subscriptions_count: currentTarget.value,
                        subscriptions: currentTarget.value ? [{ ...response.data }] : "",
                    };
                } else {
                    return row;
                }
            });
            console.log(newData[1]);
            setIsLoading(false);
            setData(newData);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };
    const columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: "اسم الكورس",
            reorder: true,
            selector: (row) => row.name,
        },
        {
            name: "مشترك ؟",
            reorder: true,
            selector: (row) => (
                <InputField
                    id={row.id}
                    type="switch"
                    value={row.subscriptions_count > 0 ? 1 : 0}
                    onChange={(input, id) => {
                        subscribe(input, id);
                    }}
                    isDisabled={isLoading}
                />
            ),
        },
        {
            name: "تاريخ الإشتراك",
            reorder: true,
            selector: (row) =>
                row.subscriptions[0] ? printFullDateTime(row.subscriptions[0].created_at) : "",
        },
        {
            name: "طريقة الاشتراك",
            reorder: true,
            selector: (row) =>
                row.subscriptions[0] ? (
                    row.subscriptions[0].is_manual ? (
                        <div className="rounded-md px-2 py-1 bg-yellow-500 text-slate-800">
                            دفع يدوي
                        </div>
                    ) : (
                        <div className="rounded-md px-2 py-1 bg-cyan-500 text-slate-100">
                            دفع اوتوماتك
                        </div>
                    )
                ) : (
                    ""
                ),
            sortable: true,
            sortField: "id",
        },
        {
            name: "تاريخ انشاء الكورس",
            reorder: true,
            selector: (row) => row.created_at,
            sortable: true,
            sortField: "id",
            format: (row) => printFullDate(row.created_at),
        },
    ];

    const handleSort = async (column, sortDirection) => {
        // setLoading(true);

        if (column.sortField) {
            const token = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(token);

            const response = await http.get(
                `${api}?page=1&per_page=${perPage}&delay=1&sort=${column.sortField}&order=${sortDirection}`,
                config
            );

            setSort({
                column,
                sortDirection,
            });

            setData(response.data.data);
            setTotalRows(response.data.total);
        }
        // setLoading(false);
    };

    const fetchUsers = async (page) => {
        // setLoading(true);

        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        const response = await http.get(
            `${api}?page=${page}&per_page=${perPage}&delay=1&sort=${sort.column.sortField}&order=${sort.sortDirection}`,
            config
        );

        setData(response.data.data);
        setTotalRows(response.data.total);
        // setLoading(false);
    };

    const handlePageChange = (page) => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        // setLoading(true);

        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        const response = await http.get(
            `${api}?page=${page}&per_page=${newPerPage}&delay=1&sort=${sort.column.sortField}&order=${sort.sortDirection}`,
            config
        );

        setData(response.data.data);
        setPerPage(newPerPage);
        // setLoading(false);
    };
    useEffect(() => {
        fetchUsers(1); // fetch page 1 of users
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Table
                title={""}
                columns={columns}
                data={data}
                // progressPending={loading}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                paginationServer
                sortServer
                pagination
            />
        </>
    );
};

export default UserCoursesSubscriptonsTable;
