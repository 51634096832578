import React, { useContext, useEffect, useState } from "react";

import AuthContext from "../../context/AuthContext";

import "./MainSection.css";

// import FlexRowReverse from "../../components/ui/FlexRowReverse";
import Container from "../../components/ui/Container";
// import Waleed from "../../components/svgs/Waleed";
// import YearsBooks from "../../components/svgs/YearsBooks";
// import Shapes from "../../components/svgs/Shapes";
// import Waves from "../../components/ui/Waves";
import profile from "../../assets/profile.png";
import bg from "../../assets/bg-rock.png";
import bggeo from "../../assets/bggeo.png";
import bgbigrock from "../../assets/bg-big-rocks.png";
import ProfileSvg from "./ProfileSvg";
import BackgroundSvg from "./BackgroundSvg";
import HelmakSvg from "./HelmakSvg";
import ScrollingContext from "../../context/ScrollingContext";
// import bgBottomLeft from "../../assets/bg-bttom-left.svg";
// import bgTopRight from "../../assets/bg-top-right.svg";
import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
const MainSection = ({ title = false }) => {
    const { token } = useContext(AuthContext);
    // const token = auth.getToken();

    const { scrolling } = useContext(ScrollingContext);

    const [animatingPercentage, setAnimatingPercentage] = useState(0);

    const [wrapperPosition, setWrapperPosition] = useState({ x: 0 });

    const [className, setClassName] = useState("");

    const [profileAnimatingPercentage, setProfileAnimatingPercentage] = useState({ x: 0, y: 0 });

    const animateHelmak = () => {
        const sectionWrapper = document.getElementById("section_wrapper");

        const sectionHeight = sectionWrapper.clientHeight;
        const windowHeight = window.innerHeight;

        const animatingMargin = sectionHeight - windowHeight;

        let percentage = scrolling / animatingMargin;

        const offsetTop = sectionWrapper.offsetTop;
        console.log(offsetTop);

        if (scrolling <= animatingMargin) {
            setClassName("moving");
            setWrapperPosition(0);
        } else {
            setWrapperPosition(animatingMargin - scrolling);
            // setClassName("static");
        }
        setAnimatingPercentage(percentage);
    };

    const onMouseMove = (e) => {
        let mainSection = document.getElementById("main_section");
        let mainSectionHeight = mainSection.clientHeight;
        let mainSectionWidth = mainSection.clientWidth;

        let mainSectionOffsetTop = mainSection.offsetTop + mainSection.offsetParent.offsetTop;

        // let ammountToBeMoved = 20;

        // console.log(e.pageY - mainSection.offsetTop, mainSectionHeight);

        let xPercentage = (e.pageX * 2) / mainSectionWidth - 1;
        let yPercentage = ((e.pageY - mainSectionOffsetTop) * 2) / mainSectionHeight - 1;
        // console.log(xPercentage, yPercentage);

        // let x = xPercentage * ammountToBeMoved;
        // let y = yPercentage * ammountToBeMoved;
        // console.log()

        setProfileAnimatingPercentage({
            x: xPercentage,
            y: yPercentage,
        });

        // setProfileTransform(`translate(${x}px,${y}px)`);
    };

    const [animatingCountoursPercentage, setAnimatingCountoursPercentage] = useState(0);

    useEffect(() => {
        const aboutSection = document.getElementById("countourWrapper");

        let aboutSectionHeight = aboutSection.clientHeight;

        let aboutSectionOffsetTop =
            aboutSection.offsetTop +
            aboutSection.offsetParent.offsetTop +
            aboutSection.offsetParent.offsetParent.offsetTop;

        let windowHeight = window.innerHeight;

        let startPoint = aboutSectionOffsetTop - windowHeight;
        let endPoint = aboutSectionOffsetTop + aboutSectionHeight / 2;

        // console.log(scrolling, startPoint, endPoint);

        let percentage = (scrolling - startPoint) / (endPoint - startPoint);

        if (percentage <= 1 && percentage >= 0) {
            setAnimatingCountoursPercentage(percentage);
        } else if (percentage > 1) {
            setAnimatingCountoursPercentage(1);
        } else {
            setAnimatingCountoursPercentage(0);
        }

        // console.log(scrolling, startPoint, endPoint);
        // console.log(percentage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrolling]);

    useEffect(() => {
        animateHelmak();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrolling]);

    return (
        <>
            <section className="negative-nav-margin shadow-large relative z-10">
                <div className="main_section_logo_animation relative" id="section_wrapper">
                    <div className="w-full h-full absolute inset-0 smooth"></div>
                    <div
                        className="inset-0 w-full h-full z-0 absolute opacity-40 dark:opacity-35  invert-0 dark:invert smooth"
                        style={{
                            backgroundImage: "url(" + bg + ")",
                            backgroundSize: "contain",
                            // backgroundPosition: "center top",
                            backgroundRepeat: "repeat",
                        }}
                    ></div>
                    <div
                        className="inset-0 w-full h-full z-0 absolute opacity-40 dark:opacity-20  invert-0 dark:invert smooth"
                        style={{
                            backgroundImage: "url(" + bgbigrock + ")",
                            backgroundSize: "contain",
                            // backgroundPosition: "center top",
                            backgroundRepeat: "repeat",
                        }}
                    ></div>
                    <div
                        id="helmak_wrapper"
                        className={`w-full h-screen helmak_wrapper ${className} flex-center-both trasnform`}
                        style={{
                            transform: `translateY(${wrapperPosition}px)`,
                        }}
                    >
                        <div className="max-w-md md:w-1/2 w-2/3">
                            <HelmakSvg percentage={1 - animatingPercentage} />
                            {!token ? (
                                <div className="flex-center-both">
                                    <Button
                                        color="orange"
                                        className="saturate-50"
                                        element="Link"
                                        to="/login"
                                    >
                                        تسجيل الدخول !
                                    </Button>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <div
                id="countourWrapper"
                className="relative z-0 bg-orange-200 dark:bg-orange-800 saturate-50"
                style={{
                    height: `${animatingCountoursPercentage * 10}rem`,
                    transition: ".1 ease",
                }}
            >
                <div
                    className="absolute z-0 right-0 top-0 bottom-0 h-full bg-blue-500 dark:bg-blue-900 smooth saturate-50 shadow-large flex-center-both clr-text-primary"
                    style={{
                        width: `${50 + animatingCountoursPercentage * 20}%`,
                        transition: "background var(--transition-smooth) ease, transform 0.1 ease",
                    }}
                >
                    <span className="opacity-30">٧٠,٩٠٪</span>
                </div>
                <div
                    className="absolute z-0 left-0 top-0 bottom-0 h-full flex-center-both clr-text-primary smooth"
                    style={{
                        width: `${50 + animatingCountoursPercentage * -20}%`,
                        transition: "background var(--transition-smooth) ease, transform 0.1 ease",
                    }}
                >
                    <span className="opacity-30">٢٩,١٠٪</span>
                </div>

                <div
                    className=" inset-0 w-full h-36 z-0 absolute smooth opacity-10 invert-0 dark:invert dark:opacity-15"
                    style={{
                        backgroundImage: "url(" + bggeo + ")",
                        backgroundSize: "contain",
                        // backgroundPosition: "center top",
                        backgroundRepeat: "repeat-x",
                    }}
                ></div>
                <div
                    className="absolute z-0 right-0 top-0 bottom-0 h-full text-blue-500 dark:text-blue-900 gradient-transparency-to-bottom smooth"
                    style={{
                        width: `${50 + animatingCountoursPercentage * 20}%`,
                        transition: "background var(--transition-smooth) ease, transform 0.1 ease",
                    }}
                ></div>
                <div
                    className="absolute z-0 left-0 top-0 bottom-0 h-full gradient-transparency-to-bottom text-orange-200 dark:text-orange-800 smooth"
                    style={{
                        width: `${50 + animatingCountoursPercentage * -20}%`,
                        transition: "background var(--transition-smooth) ease, transform 0.1 ease",
                    }}
                ></div>
            </div>
            <section
                id="main_section"
                onMouseMove={onMouseMove}
                className="md:h-screen min-h-screen bg-[#333f5a] relative overflow-hidden shadow-large z-10"
            >
                <div
                    className="inset-0 w-full h-full z-0 absolute opacity-30"
                    style={{
                        backgroundImage: "url(" + bg + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "center top",
                    }}
                ></div>
                <div className="inset-0 w-full h-full z-0 absolute opacity-75">
                    <BackgroundSvg percentage={profileAnimatingPercentage} />
                </div>
                <Container className="h-full relative z-10 font-fs pt-32 md:pt-0 pb-20 md:pb-0">
                    <div className="w-full flex md:flex-row flex-col h-full space-y-20 md:space-y-0 md:px-32">
                        <div className="md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="w-full h-full flex-center-both max-w-md en md:py-20">
                                <div className="w-full h-full flex-center-both">
                                    <ProfileSvg percentage={profileAnimatingPercentage} />
                                </div>
                            </div>
                        </div>
                        {/* <div className=" md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="items-center flex-col md:justify-start justify-center clr-white smooth space-y-6 md:space-y-0 font-lale">
                                <h2 className="font-big font-w-bold flex">
                                    <div className="relative bg-box text-[#080d20]">
                                        <div className="text-[#f48b1c] relative z-1">الچيو /</div>
                                    </div>
                                </h2>
                                <h1 className="font-bigmain font-w-bold before-box relative">
                                    <span className="relative z-10">ماجد إمام</span>
                                </h1>
                                <h2 className="font-h1">مــــنصة متخــــصــــصــــة في</h2>
                                <div className="font-h1 flex">
                                    <div className="relative bg-box text-[#080d20]">
                                        <div className="text-[#f48b1c] relative z-1">
                                            علم الجيولوجيا
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className=" md:basis-1/2 basis-full h-full flex-center-both">
                            <div className="flex flex-col justify-center md:items-start items-center clr-white smooth space-y-6 md:space-y-0 font-lem">
                                <h2 className="font-h1 font-w-bold flex">
                                    <div className="relative bg-box text-[#080d20]">
                                        <div className="text-[#f48b1c] relative z-1">الچيو /</div>
                                    </div>
                                </h2>
                                <h1 className="font-bigmax font-w-bold before-box relative">
                                    <span className="relative z-10">ماجد إمام</span>
                                </h1>
                                <h2 className="font-h2">مــــنصة متخــــصــــصــــة في</h2>
                                <div className="font-h2 flex">
                                    <div className="relative bg-box text-[#080d20]">
                                        <div className="text-[#f48b1c] relative z-1">
                                            علم الجيولوجيا
                                        </div>
                                    </div>
                                </div>
                                {!token ? (
                                    <div className="py-10">
                                        <Button
                                            color="cyan"
                                            className="saturate-50"
                                            element="Link"
                                            to="/login"
                                        >
                                            تسجيل الدخول !
                                        </Button>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MainSection;
