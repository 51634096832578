import React, { useContext } from "react";

import { ScrollingProvider } from "../context/ScrollingContext";

import MainSection from "../sections/home/MainSection";
import AboutSection from "../sections/home/AboutSection";
import CoursesSection from "../sections/home/CoursesSection/CoursesSection";
import AuthContext from "../context/AuthContext";
import Button from "../components/ui/Button";

const Home = () => {
    const { token } = useContext(AuthContext);
    return (
        <>
            {token ? (
                <div className="py-10">
                    <div className="flex-center-both">
                        <Button
                            color="cyan"
                            className="saturate-50 font-h1 font-w-bold px-20 py-2"
                            element="Link"
                            to="/me/user/courses"
                        >
                            إشتراكاتك
                        </Button>
                    </div>
                </div>
            ) : (
                <ScrollingProvider>
                    <MainSection />
                    <AboutSection />
                </ScrollingProvider>
            )}

            <CoursesSection />
        </>
    );
};

export default Home;
