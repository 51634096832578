import React, { useContext } from "react";
import "./Helmak.css";
import helmak from "../../assets/helmak.png";
import helmakdarkmode from "../../assets/helmakdarkmode.png";
import ThemeContext from "../../context/ThemeContext";
const HelmakSvg = ({ percentage = 1 }) => {
    const { darkmode } = useContext(ThemeContext);
    return (
        <svg
            id="helmak"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1080 1080"
        >
            <defs>
                <style>
                    {
                        "\n      #helmak .cls-1 {\n        fill: none;\n      }\n\n      #helmak .cls-2 {\n        clip-path: url(#helmakclippath);\n      }\n\n      #helmak .cls-3 {\n        mask: url(#helmakmask);\n      }\n\n      #helmak .cls-4 {\n        fill: #fff;\n      }\n\n      #helmak .cls-5 {\n        clip-path: url(#helmakclippath-1);\n      }\n\n      #helmak .cls-6 {\n        clip-path: url(#helmakclippath-2);\n      }\n\n      .cls-7 {\n        filter: url(#luminosity-invert-noclip);\n      }\n    "
                    }
                </style>
                <clipPath id="helmakclippath">
                    <rect className="cls-1" width={1080} height={1080} />
                </clipPath>
                <filter
                    id="luminosity-invert-noclip"
                    x={-161}
                    y={-4.24}
                    width={1241}
                    height={1088.55}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feColorMatrix
                        result="cm"
                        values="-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0"
                    />
                    <feFlood floodColor="#fff" result="bg" />
                    <feBlend in="cm" in2="bg" />
                </filter>
                <mask
                    id="helmakmask"
                    x={-161}
                    y={-4.24}
                    width={1241}
                    height={1088.55}
                    maskUnits="userSpaceOnUse"
                >
                    <g className="cls-7">
                        <g>
                            <path
                                className="cls-4"
                                d="m864,768v-125s-19,1-19,1v-8.19l-39,.19,13-33s8,0,18,5l12-14,37-93v-32.11l-6-7.89s-10,3-15,0c0,0-2,7-10,12s-20,5-30,0c0,0-2-1-3-3,0,0-3,3-11,3s-10-7-10-7c0,0-7,0-12-2s-6-6-6-6c0,0-9-2-13-10s0-20.66,0-20.66l10-38.34s4-14,8-18,19,0,19,0c0,0,21,7,24,8s27,7,27,7c0,0,21,6,26,8s74-42,74-42l51,123,24,96,7.94,197-25.94,63-151-63Z"
                            />
                            <path
                                className="cls-4"
                                d="m199,767v-5l3-6s-22-1-29-2-33-6-33-6c0,0-14-3-21-7s-13-6-15-14,5-15,5-15c0,0,7-8,14-13s19-13,23-15,18-10,22-12,29-14,33-16,24-11,30-14,25-11,25-11l-11-28-4,1s-10,3-12,4l-10,5-11.72,8.18-9.9,8.87-25.39-111.05s-2-29,3-45c1.62-5.18,6-14.76,6-15,0-1,6,4,19,3h2s4,9,13,13,21,1,24,0,6-5,6-5c0,0,4,3,8,3s9-1,11-4l2-3s10-1,12-2,6-5,6-6,5-2,9-5,6-10,6-15-6-25-6-25c0,0-5-20-6-23s-4-13-6-16-2-5-7-5-17,2-22,4-17,6-21,7-19,5-23,6-19,6-23,7-9,4-13,6-18-3-18-3l-315,303,115,141,245-73Z"
                            />
                        </g>
                    </g>
                </mask>
                <clipPath id="helmakclippath-1">
                    <path
                        className="cls-1"
                        d="m864,768v-125s-19,1-19,1v-8.19l-39,.19,13-33s8,0,18,5l12-14,37-93v-32.11l-6-7.89s-10,3-15,0c0,0-2,7-10,12s-20,5-30,0c0,0-2-1-3-3,0,0-3,3-11,3s-10-7-10-7c0,0-7,0-12-2s-6-6-6-6c0,0-9-2-13-10s0-20.66,0-20.66l10-38.34s4-14,8-18,19,0,19,0c0,0,21,7,24,8s27,7,27,7c0,0,21,6,26,8s74-42,74-42l51,123,24,96,7.94,197-25.94,63-151-63Z"
                    />
                </clipPath>
                <clipPath id="helmakclippath-2">
                    <path
                        className="cls-1"
                        d="m199,767v-5l3-6s-22-1-29-2-33-6-33-6c0,0-14-3-21-7s-13-6-15-14,5-15,5-15c0,0,7-8,14-13s19-13,23-15,18-10,22-12,29-14,33-16,24-11,30-14,25-11,25-11l-11-28-4,1s-10,3-12,4l-10,5-11.72,8.18-9.9,8.87-25.39-111.05s-2-29,3-45c1.62-5.18,6-14.76,6-15,0-1,6,4,19,3h2s4,9,13,13,21,1,24,0,6-5,6-5c0,0,4,3,8,3s9-1,11-4l2-3s10-1,12-2,6-5,6-6,5-2,9-5,6-10,6-15-6-25-6-25c0,0-5-20-6-23s-4-13-6-16-2-5-7-5-17,2-22,4-17,6-21,7-19,5-23,6-19,6-23,7-9,4-13,6-18-3-18-3l-315,303,115,141,245-73Z"
                    />
                </clipPath>
                <image
                    id="helmakimage"
                    width={4000}
                    height={4032}
                    xlinkHref={darkmode ? helmakdarkmode : helmak}
                />
            </defs>
            <g className="cls-2">
                <g>
                    <g className="cls-3">
                        <use transform="translate(.08 -4.24) scale(.27)" xlinkHref="#helmakimage" />
                    </g>
                    <g
                        className="cls-5 right-arm"
                        transform={`translate(${percentage > 0 ? 100 * percentage : 0},${
                            percentage > 0 ? 10 * percentage : 0
                        }) rotate(${percentage > 0 ? 20 * percentage : 0})`}
                    >
                        <use transform="translate(.08 -4.24) scale(.27)" xlinkHref="#helmakimage" />
                    </g>
                    <g
                        className="cls-6 left-arm relative z-10"
                        transform={`translate(${percentage > 0 ? -100 * percentage : 0},${
                            percentage > 0 ? 10 * percentage : 0
                        }) rotate(${percentage > 0 ? -20 * percentage : 0})`}
                    >
                        <use transform="translate(.08 -4.24) scale(.27)" xlinkHref="#helmakimage" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default HelmakSvg;
