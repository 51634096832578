import React from "react";
import "./BackgroundSvg.css";

const BackgroundSvg = ({ percentage }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_2"
            data-name="Layer 2"
            viewBox="0 0 2542.56 1232.1"
        >
            <defs>
                <style>
                    {`
                    .wrapper_rock_2{
                        transform: translate(${percentage.x * 20}px,${percentage.y * 20}px);
                    }
                    `}
                    {`
                    .wrapper_rock_10{
                        transform: translate(${percentage.x * -20}px,${percentage.y * -20}px);
                    }
                    `}
                </style>
            </defs>
            <g
                id="rocks"
                data-name="Layer 2"
                style={{
                    opacity: 0.1,
                }}
            >
                <path d="m1985.44 112.25 33.91 9.74 51.51 9.79 38.13 37.15 45.85 39.53-66.42 28.95-75.79 4.56-23.7 9.7-19.56-15.15-17.88-19.19-8.28-45.97 2.26-38.7 39.97-20.41z" />

                <path d="m839.04 112.25 33.91 9.74 51.51 9.79 38.14 37.15 45.84 39.53-66.42 28.95-75.78 4.56-23.7 9.7-19.57-15.15-17.87-19.19-8.29-45.97 2.26-38.7 39.97-20.41z" />

                <path d="m1624.23 419.37-9.08-10.1-21.6-38.04.07-26.79 29.52 10.92 25.94 15.09 4.6 8.06 4.54 7.61-.91 7.32-5.13 18.56-10.84 4.51-17.11 2.86z" />

                <g className="wrapper_rock_2 smooth">
                    <path
                        d="m40.34 1071.43 3.92-25.79 32.33-35.28 11.67-45.17 24.67-39.02 22.74-48.5 28.49-6.69 26.69 9.03 42.94 50.34 15.98 61.8-11.32 36.97-23.32 33.17-17.96-3.97-51.95 20.2-52.82-14.63-52.06 7.54zM0 268.92l9.59 7.94 42.47 21.7 21.67 42.55 17.41 48.03-23.7 45.15-16.71 24.22-43.54-1.92"
                        className="rock-cls-2"
                    />
                    <path
                        d="m662.42 59.78-17.8 46.95-8.19 50.55-13.36 8L576 215.63l-17.69-5.42-67.43-22.82.68-38.61-4.06-44.71 7.79-13.86 44.79-26.93 49.15-18.73 15.1-5.96 58.09 21.19z"
                        className="rock-cls-2"
                    />
                    <path
                        d="m2333.13 1071.43 3.92-25.79 32.33-35.28 11.67-45.17 24.67-39.02 22.74-48.5 28.49-6.69 26.69 9.03 42.94 50.34 15.98 61.8-11.31 36.97-23.33 33.17-17.96-3.97-51.95 20.2-52.82-14.63-52.06 7.54zM2199.36 328.85l37.17-19.94 31.58-25.41 24.68-14.58 9.59 7.94 42.47 21.7 21.67 42.55 17.41 48.03-23.7 45.15-16.71 24.22-43.54-1.92-41.34-11.08-3.78-16.12-32.99-47.34-22.51-53.2z"
                        className="rock-cls-2"
                    />

                    <path
                        d="m1186.74 1071.43 3.92-25.79 32.33-35.28 11.67-45.17 24.66-39.02 22.74-48.5 28.49-6.69 26.69 9.03 42.95 50.34 15.97 61.8-11.31 36.97-23.32 33.17-17.96-3.97-51.95 20.2-52.82-14.63-52.06 7.54zM1052.97 328.85l37.16-19.94 31.59-25.41 24.68-14.58 9.58 7.94 42.48 21.7 21.66 42.55 17.41 48.03-23.7 45.15-16.71 24.22-43.54-1.92-41.34-11.08-3.78-16.12-32.99-47.34-22.5-53.2z"
                        className="rock-cls-2"
                    />

                    <path
                        d="m1808.82 59.78-17.8 46.95-8.19 50.55-13.36 8-47.07 50.35-17.7-5.42-67.43-22.82.69-38.61-4.07-44.71 7.8-13.86 44.78-26.93 49.16-18.73 15.1-5.96 58.09 21.19z"
                        className="rock-cls-2"
                    />
                </g>
                <path
                    d="m1948.37 1014.08-12.55 11.59-31.99 35.86-38.05 33.67-38.4-32.6-3.45-10.12 45.19-36.31 41.26 4.27 37.99-6.36z"
                    className="rock-cls-3"
                />

                <path
                    d="m801.98 1014.08-12.56 11.59-31.99 35.86-38.04 33.67-38.4-32.6-3.46-10.12 45.19-36.31 41.26 4.27 38-6.36z"
                    className="rock-cls-3"
                />
                <path
                    d="m699.17 428.2-30.92-1.06-63.62-42.21-4.47-12.42 35.41-32.2 51.66-15.95 25.69 38.12 2.35 7.93-16.1 57.79z"
                    className="rock-cls-4"
                />
                <path
                    d="m2013.62 426.82 25.4 31.74 14.44 48.2-1.12 50.6 8.75 25.95-77.35-9.45-65.91-38.29 6.39-62.61 18.49-2.54 35.53-22.72 35.38-20.88zM2062.85 1082.72l15.43-31.37 57.9-49.98 17.82-9.36 15.46 50.62-1.44 52.59-39.37 22.2-12.82-2.6-52.98-32.1z"
                    className="rock-cls-4"
                />
                <path
                    d="m867.22 426.82 25.4 31.74 14.45 48.2-1.13 50.6 8.76 25.95-77.36-9.45-65.9-38.29 6.39-62.61 18.48-2.54 35.53-22.72 35.38-20.88zM916.45 1082.72l15.43-31.37 57.9-49.98 17.83-9.36 15.46 50.62-1.44 52.59-39.38 22.2-12.81-2.6-52.99-32.1zM1845.56 428.2l-30.92-1.06-63.61-42.21-4.47-12.42 35.41-32.2 51.65-15.95 25.69 38.12 2.35 7.93-16.1 57.79z"
                    className="rock-cls-4"
                />
                <path
                    d="m2382.66 637.08 8.83 68.47-10.72 19.89-47.68 33.03-41.91 44.97-68.27-39.66-6.65-11.18-16.88-49.7-12.06-50.99 11.24-17.14 49.87-5.03 41.77-23.47 33.33-.97 59.13 31.78z"
                    className="rock-cls-5"
                />
                <path
                    d="m1236.26 637.08 8.83 68.47-10.71 19.89-47.68 33.03-41.92 44.97-68.26-39.66-6.66-11.18-16.87-49.7-12.07-50.99 11.24-17.14 49.88-5.03 41.76-23.47 33.33-.97 59.13 31.78zM1684.48 558.53l33.55-26.11 18.96-.75 58.77 36.11 3.12 44.38-6.65 7.07-47.01 41.38-4.39-10.45-47.36-32.49-11.15-17.9 2.16-41.24z"
                    className="rock-cls-5"
                />
                <path
                    d="m30.73 605.3 59.13 31.78 8.84 68.47-10.72 19.89-47.68 33.03M538.09 558.53l33.54-26.11 18.96-.75 58.77 36.11 3.13 44.38-6.65 7.07-47.01 41.38-4.4-10.45-47.35-32.49-11.15-17.9 2.16-41.24z"
                    className="rock-cls-5"
                />
                <path
                    d="m1339.48 682.18-4.7 13.56-42.76 6.07-1.11-14.22-1.93-17.73 17.39-16.6.79 4.54 32.32 24.38z"
                    className="rock-cls-6"
                />
                <path
                    d="m2485.87 682.18-4.69 13.56-42.76 6.07-1.11-14.22-1.94-17.73 17.4-16.6.79 4.54 32.31 24.38z"
                    className="rock-cls-6"
                />

                <path
                    d="m193.08 682.18-4.7 13.56-42.75 6.07-1.12-14.22-1.93-17.73 17.4-16.6.79 4.54 32.31 24.38z"
                    className="rock-cls-6"
                />
                <path
                    d="m270.01 693.64 29.38-12.68 53.22 6.67 53.18 2.47 53.07-10.03 34.42 34.82-22.05 51.61-29.98 46.63-17.43 41.69-40.54 21.15-37.42 21.54-21.74-54.45-.76-54.68-32.92-42.82-20.43-51.92z"
                    className="rock-cls-7"
                />
                <path
                    d="m1416.41 693.64 29.37-12.68 53.23 6.67 53.18 2.47 53.06-10.03 34.43 34.82-22.06 51.61-29.98 46.63-17.43 41.69-40.53 21.15-37.42 21.54-21.75-54.45-.75-54.68-32.92-42.82-20.43-51.92z"
                    className="rock-cls-7"
                />
                <path d="m477.83 419.37-9.07-10.1-21.61-38.04.07-26.79 29.52 10.92 25.94 15.09 4.6 8.06 4.54 7.61-.9 7.32-5.13 18.56-10.84 4.51-17.12 2.86z" />

                <path
                    d="m427.49 1169.83-16.12 49.14-17.51-14.82-16.59-16.11-7.7-10.57 4.01-9.01 40.42-7.68 5.72.51 7.77 8.54z"
                    className="rock-cls-8"
                />
                <path
                    d="m1573.89 1169.83-16.12 49.14-17.52-14.82-16.58-16.11-7.7-10.57 4.01-9.01 40.41-7.68 5.73.51 7.77 8.54z"
                    className="rock-cls-8"
                />
                <path
                    d="m1186.83 149-42.03 36.83-9.63-4.98-21.43-32.81 34.49-16.44 6.04-.74 32.56 18.14z"
                    className="rock-cls-9"
                />
                <path
                    d="m2333.23 149-42.04 36.83-9.62-4.98-21.43-32.81 34.49-16.44 6.04-.74 32.56 18.14z"
                    className="rock-cls-9"
                />
                <g className="wrapper_rock_10 smooth">
                    <path
                        d="m286 68.02 31.05 22.82 20.81 35.43 17.72 10.22-9.2 41.53.66 18.26-58.46 33.49-14.19 13.47-42.32-10.41-43.23-4.99 2.89-6.94 10.16-59.71 11.35-60.52 10.97-18.45L286 68.02z"
                        className="rock-cls-10"
                    />

                    <path
                        d="m928.42 50.29 10.16-44.44 1.93-3.27L950.83 0l47.81 14.56 1.3 2.51 12.4 41.94-7.86 4.78-21.62 15.05-9.02-1.49-45.42-27.06z"
                        className="rock-cls-10"
                    />
                    <path
                        d="m775.75 779.96 43.5-18.44 48.82-8.96 47.59 10.53 15.78-.2 4.99 53.78-10.18 53.2-12.96 50.88-65.66-.32-21-8.91-19.34-43.58-20.87-42.8-10.67-45.18z"
                        className="rock-cls-10"
                    />

                    <path
                        d="m1432.4 68.02 31.05 22.82 20.81 35.43 17.72 10.22-9.2 41.53.65 18.26-58.45 33.49-14.19 13.47-42.33-10.41-43.22-4.99 2.89-6.94 10.16-59.71 11.35-60.52 10.97-18.45 61.79-14.2z"
                        className="rock-cls-10"
                    />
                    <path
                        d="m1922.14 779.96 43.5-18.44 48.82-8.96 47.59 10.53 15.79-.2 4.99 53.78-10.19 53.2-12.96 50.88-65.65-.32-21-8.91-19.35-43.58-20.86-42.8-10.68-45.18z"
                        className="rock-cls-10"
                    />
                    <path
                        d="m2524.86 232.83-43.22-4.99 2.88-6.94 10.16-59.71 11.35-60.52 10.98-18.45"
                        className="rock-cls-10"
                    />
                </g>
                <path
                    d="m741.83 1193.49 33.34-29.92 24.24 15.84 5.19 35.24-9.01 2.88-15.08 14.57-21.37-9.62-17.31-28.99z"
                    className="rock-cls-11"
                />
                <path
                    d="m1888.23 1193.49 33.34-29.92 24.24 15.84 5.18 35.24-9 2.88-15.08 14.57-21.37-9.62-17.31-28.99z"
                    className="rock-cls-11"
                />
                <path
                    d="m1284.96 121.52-39.91-20.54-3.6-8.47-7.81-38.6 47.96-13.19 36.9 34-4.23 9.57-29.31 37.23zM952 553.2l-5.82-42.26 46.5-16.29 7.92 13.77 14.52 37.17-15.06 15.29-6.88 2.04L952 553.2z"
                    className="rock-cls-12"
                />
                <path
                    d="m2431.36 121.52-39.92-20.54-3.59-8.47-7.82-38.6L2428 40.72l36.9 34-4.23 9.57-29.31 37.23zM2098.39 553.2l-5.82-42.26 46.51-16.29 7.92 13.77 14.52 37.17-15.07 15.29-6.87 2.04-41.19-9.72z"
                    className="rock-cls-12"
                />
                <path
                    d="M1794.34 825.9c-10.5 18.64-20.29 36.22-30.3 53.66-3.12 5.43-12.75 7.43-18.59 3.97-6.97-4.13-13.71-8.63-21.01-13.27 3.75-22.38 7.48-44.58 11.32-67.48 9.51-.43 18.61-.96 27.71-1.14 1.9-.04 4.23.77 5.66 1.99 8.29 7.08 16.36 14.4 25.21 22.27Z"
                    className="rock-cls-12"
                />
                <path
                    d="m508.24 949.64 38.43-14.95 43.31-5.13 20.7 41.63 30.43 33.44-28.97 55.72-7.43 12.06-38.84 15.55-36.45 21.09-13.43-2.82-3.61-52.97-2.65-52.3-1.49-51.32zM421.67 505.47l-38.92 10.26-43.55 13.88-46.42 18.47-48.44-32.21-40.78-31.57 27.5-54.38 18.71-5.06 56.04-35.07 63.53-13.89 15.77-.49 15.68 44.2 17.58 43.36 3.3 42.5z"
                    className="rock-cls-13"
                />

                <path
                    d="m2074.82 50.29 10.15-44.44 1.93-3.27L2097.23 0l47.81 14.56 1.3 2.51 12.39 41.94-7.85 4.78-21.62 15.05-9.03-1.49-45.41-27.06z"
                    className="rock-cls-10"
                />

                <path
                    d="m2537.13 515.87-40.78-31.57 27.5-54.38 18.71-5.06"
                    className="rock-cls-13"
                />
                <path
                    d="m1654.63 949.64 38.44-14.95 43.3-5.13 20.71 41.63 30.43 33.44-28.97 55.72-7.44 12.06-38.84 15.55-36.45 21.09-13.42-2.82-3.61-52.97-2.65-52.3-1.5-51.32zM1568.07 505.47l-38.92 10.26-43.55 13.88-46.42 18.47-48.45-32.21-40.77-31.57 27.5-54.38 18.71-5.06 56.03-35.07 63.54-13.89 15.77-.49 15.68 44.2 17.57 43.36 3.31 42.5z"
                    className="rock-cls-13"
                />
            </g>
        </svg>
    );
};

export default BackgroundSvg;
