import React, { useEffect, useState } from "react";
import InputIcon from "../../components/form/elements/InputIcon";
import AdminForm from "../../components/ui/AdminForm";
import auth from "../../services/authServices";
import {
    handleFormErrors,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import http from "../../services/httpServices";
import modal from "../../services/modalServices";

const AdminToAdminNoteForm = ({ user = { id: 0 }, afterSuccess = () => null }) => {
    const [data, setData] = useState({
        admin_to_admin_note: "",
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const inputFields = [
        {
            id: "admin_to_admin_note",
            togglePassword: true,
            placeholder: "اترك نوت للأدمن",
            icon: <InputIcon icon="simple-line-icons:note" />,
        },
    ];
    const handleSubmit = (e) => {
        // return;

        handleFormSubmit(e, setIsLoading, setErrors, () => {
            modal.message({
                title: "هل انت متأكد",
                text: ``,
                icon: "info",
                // button: "تأكيد",
                buttons: {
                    confirm: "تأكيد",
                    cancel: "إلغاء",
                },
                callback: (e) => {
                    if (e && e !== "cancel") {
                        handleAdminToAdminNoteChange();
                    } else {
                        setIsLoading(false);
                    }
                },
            });
        });
    };
    const handleAdminToAdminNoteChange = async () => {
        const adminToken = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(adminToken);

        try {
            setIsLoading(false);

            const { data: response } = await http.post(
                `/api/users/${user.id}/admin_to_admin_note`,
                data,
                config
            );

            modal.message({
                title: `عملية ناجحة`,
                text: "تم تعديل الرسالة بنجاح",
                callback: () => {
                    afterSuccess();
                },
            });
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };

    useEffect(() => {
        setData({ admin_to_admin_note: user.admin_to_admin_note });
    }, [user]);

    return (
        <AdminForm
            onSubmit={handleSubmit}
            isLoading={isLoading}
            buttonText="تعديل نوت للأدمن"
            className="space-y-4 py-0 pb-16"
        >
            <div className="font-h1 text-center font-w-bold">ملاحظات الادمن</div>
            <div className="pb-4">
                <div className="w-full px-2 py-3 bg-yellow-200 text-slate-600 rounded-md shadow-md text-center">
                    {user.admin_to_admin_note ? user.admin_to_admin_note : "لا توجد نوت سابقة"}
                </div>
            </div>
            {inputFields.map((input, key) =>
                renderInputFields(
                    key,
                    input.handleChange ? input.handleChange : handleChange,
                    data,
                    setData,
                    errors,
                    input
                )
            )}
        </AdminForm>
    );
};

export default AdminToAdminNoteForm;
