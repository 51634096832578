import React, { useEffect } from "react";
import "./Afer.css";
import { getPartialPercentage } from "../../services/scrollingServices";

const AferSvg = ({ percentage = 0 }) => {
    useEffect(() => {
        const alphs = document.querySelectorAll("#afer path");
        alphs.forEach((path) => {
            var length = path.getTotalLength();
            // path.style.transition = path.style.WebkitTransition = "none";

            path.style.strokeDashoffset = length;
            // path.style.transition = path.style.WebkitTransition = "stroke-dashoffset .4s ease";
        });
        const quantity = alphs.length;
        const levelAmount = 1 / quantity;

        for (let i = 0; i < quantity; i++) {
            let initialValue = i * levelAmount;
            let finishValue = (i + 1) * levelAmount;

            if (percentage <= initialValue || percentage >= finishValue) {
                continue;
            }

            let newPercentage = getPartialPercentage(percentage, initialValue, finishValue);

            let length = alphs[i].getTotalLength();
            alphs[i].style.strokeDashoffset = (1 - newPercentage) * length;

            alphs.forEach((path, index) => {
                var length = path.getTotalLength();
                // console.log(index, i)
                if (index < i) {
                    path.style.strokeDashoffset = 0;
                    path.classList.add("toFill");
                } else if (index === i) {
                    path.classList.remove("toFill");
                } else {
                    path.classList.remove("toFill");
                    path.style.strokeDashoffset = length;
                }
            });
            break;
        }
        alphs.forEach((path, index) => {
            var length = path.getTotalLength();
            path.style.strokeDasharray = length + " " + length;
            if (percentage >= 1) {
                path.classList.add("toFill");
                path.style.strokeDashoffset = 0;
            } else if (percentage <= 0) {
                path.style.strokeDashoffset = length;
                path.classList.remove("toFill");
            }
        });
    }, [percentage]);
    return (
        <svg
            id="afer"
            className=""
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            style={{
                enableBackground: "new 0 0 1366 378",
            }}
            viewBox="0 0 1366 378"
        >
            <path d="m1332.6 220.3-13.2 35.8-86.8 9.2 13.7-35.8 12.3-1.4c-.2-.6-.2-1.6-.2-2.8 0-4.6 1.4-10 4.2-16.4 2.8-6.4 6.3-12.5 10.4-18.4 4.1-5.9 7.7-10.1 10.8-12.6 3.8-2.8 10.2-5.6 19.3-8.3 9.1-2.7 16.8-4.2 23.1-4.7l-10.1 33.5c-13.7 1.3-26 3.6-37 7.1 2.7 4.1 6.3 7.8 10.8 11.2 4.6 3.4 9.3 5.6 14.2 6.7l28.5-3.1z" />
            <path d="M1182.3 126.5c2.7 6 4.1 13.6 4.1 22.9 0 7.7-1.5 16.7-4.6 27-3.1 10.3-6.6 18.2-10.7 23.7-3.1 3.9-8.8 7.5-17.1 10.8-8.3 3.3-18.7 5.7-31.5 7.1l-9.2.9 13.7-35.8 14.6-1.4c9.3-1.1 18-2.7 26.2-4.7l-.9-8H1155c-6 0-10.8-.9-14.4-2.7-3.6-1.8-5.4-4.4-5.4-7.7 0-3.8 2.3-9 6.8-15.6 4.6-6.6 9.7-12.5 15.6-17.7 5.8-5.2 10.5-7.8 14.2-7.8 4.2 0 7.7 3 10.5 9zm-32.4-44.4 21.9-14.6 3.3 5.4c4.4 7.2 8.1 12.7 11.1 16.5l-19.8 13c-5.5-4.2-11-11-16.5-20.3zM1247.7 233.5l-10.1 27.6-5 4.2-22.6 2.4c-.2-15.4-.9-34.2-2.2-56.5-1.3-22.2-2.8-42-4.4-59.3 4.4-5.3 9.4-10.5 14.9-15.3 5.5-4.9 10.5-8.5 14.9-10.8.8 3.1 1.2 8.1 1.2 14.9.2 12.1-.6 28.3-2.4 48.7-1.7 20.4-3.3 34.5-4.7 42.3l19.1-2.1 1.3 3.9z" />
            <path d="m939 173.9-60.8 6.4c-1.9 9.3-3.6 21.1-5.2 35.4l-7.1-.2 1.2-10.6c.5-6.6 1.3-14.5 2.6-23.8l-21.7 2.4 13.7-35.8 16.3-1.9c6.6-18.7 15.6-33 27.1-42.9C916.6 93 929.3 88 943.3 88c4.2 0 8 .4 11.3 1.3 3.3.9 5.8 2 7.5 3.4-2.8 25.9-10.5 53-23.1 81.2zM903.9 129c-6.1 3.6-11.1 8.7-14.9 15.3l44.6-5c.8-3.6 1.6-8.4 2.4-14.4-3.3-.9-7-1.4-11.1-1.4-7.9.1-14.9 1.9-21 5.5zM1128.4 187.1l-10.1 27.6-5 4.2-12.5 1.2c-4.2 14.6-10.8 26.9-19.6 36.8-8.8 9.9-19.7 14.9-32.5 14.9-6.9 0-15.2-1.8-24.8-5.5-9.6-3.7-16.4-7.5-20.3-11.4 14.5-4.2 30.1-9.6 47.1-15.9 16.9-6.4 30.3-12.2 40.2-17.6l12-24.5c2.4-4.7 4.7-7.9 7.1-9.6s6.3-2.8 11.8-3.4l5.2-.7 1.4 3.9z" />
            <path d="M863.1 151.5 852.9 179l-5 4.2-22.6 2.4c.3-6.6.5-16.7.5-30.2 0-3.8-.1-6.6-.2-8.5-2.7 9.9-5.9 18.9-9.7 27.1-8.5 2.8-18.1 4.8-28.8 5.9l13.7-35.8c9-.8 17.1-2.3 24.3-4.5-.9-10.8-2.7-21.6-5.3-32.3-2.6-10.7-5.5-20-8.8-27.8 3.8-6.6 8.2-13 13.2-19.1 5-6.1 9.7-10.9 14.2-14.4 1.6 3.8 3.1 11.9 4.6 24.4 1.5 12.5 2.2 23.6 2.2 33.4 0 9.3-.2 18.1-.7 26.5-.5 8.4-1 14.7-1.7 19l18.9-1.9 1.4 4.1z" />
            <path d="m802.2 148.2-10.1 27.6-5 4.2-6.8.7-2.1.2c1.4 2 2.1 4 2.1 5.9 0 6-2.2 12.6-6.5 19.8-4.3 7.2-9.3 13.1-15 17.5-5.3 1.6-11.1 2.9-17.2 4s-15.1 2.3-26.9 3.5l13.7-35.8 6.8-.7h.5v-.5c0-2.2 1-6.1 2.9-11.7 2-5.6 4.3-11.1 7-16.5s5.1-9.2 7.3-11.2c4.1-4.4 17.1-7.8 38.9-10.1l9-.9 1.4 4z" />
            <path d="m472.5 274.1 42.7-11.6-4.7 24.3-42 9.2 4-21.9zm49.7-98.5c3.7 6.5 5.5 12.2 5.5 17.1 0 5.8-1.5 13.3-4.6 22.5-3.1 9.2-6.4 16.4-10 21.6-4.2 3-10.4 5.8-18.4 8.5s-16.5 4.5-25.5 5.4l13.7-35.8 28.1-2.8c-5.2-7.2-14.3-15.3-27.4-24.3l25.7-27.6c4.9 3.8 9.2 8.9 12.9 15.4zM729.8 199.8l-10.1 27.6-5 4.2-10.1 1.2c-2.4 7.1-4.9 12.2-7.5 15.3-6.8 6.1-16.2 11.4-28.4 15.8s-24.4 6.6-36.7 6.6c-8 0-15.9-1-23.7-3.1-7.8-2-14.4-4.8-19.9-8.3-5.5-3.5-9-7.2-10.4-11.1 0-7.1 3.3-16.9 9.9-29.6 6.6-12.7 15.2-25.1 25.7-37.3 10.5-12.2 21.5-21.3 33-27.5l17.9 38c-4.4 8.5-10.3 16.3-17.7 23.3-1.3.2-3.1.2-5.4.2-7.4 0-15-1.1-22.9-3.3l14.6-15.3 12.3 2.1-8.5-15.6c-6.3 4.4-12.9 10.2-19.7 17.3-6.8 7.2-12.8 14.1-17.8 20.9 2 3.9 7 7.1 14.7 9.6 7.8 2.4 17.3 3.7 28.7 3.7 9.9 0 19.2-1 27.9-2.9 8.7-2 16-4.4 21.8-7.4-.9-9.4-3-21-6.3-34.7-3.2-13.7-6.5-25.4-9.8-35.1 3.8-6.6 8.2-13 13.2-19.1 5-6.1 9.7-10.9 14.2-14.4 1.6 4.6 3.1 14.7 4.5 30.4s2.2 31.1 2.4 46.2l17.7-1.9 1.4 4.2z" />
            <path d="m397.5 223.9 85.4-9 1.4 4-10.1 27.6-5 4.2-85.4 9 13.7-35.8z" />
            <path d="m398.9 227.9-10.1 27.6-5 4.2-25.9 2.6 2.8-9.7c-5.3 6.9-15.6 11.2-30.9 12.7l13.7-35.8c11-1.1 19.8-3.4 26.4-6.8l5.2-17.2 7.5.7-4.2 19.6 19.1-1.9 1.4 4zm-39.8-65.1 42.7-11.6-4.7 24.3-42 9.2 4-21.9z" />
            <path d="M263.8 93.3c6.8 6.5 11.8 14.7 15.2 24.5 3.4 9.8 5.1 19.7 5.1 29.6 0 11.5-1.4 21.4-4.1 29.7-2.8 8.3-6.1 15.9-10 22.6-3.5 3.5-8.6 7-15.4 10.7-6.8 3.7-14.1 6.8-21.7 9.2-7.6 2.4-14.2 3.7-19.7 3.7-4.6 0-9.2-.8-13.9-2.4-12.3 5-28.1 8.6-47.6 10.6l13.7-35.8 13.2-1.4c-.3-1.9-.5-3.4-.5-4.5 2.2-14.3 7.2-27.4 14.9-39.2 7.7-11.8 16.7-19.8 27.1-24.1 5.2 1.9 9.7 6.2 13.6 13 3.9 6.8 5.8 14.1 5.8 21.9 0 8-2.1 16.3-6.4 24.8 12.7-1.9 22.9-4.2 30.4-6.8-.3-11.6-2.8-22.8-7.5-33.5s-10.8-19.3-18.2-25.9c-7.4-6.6-15-9.9-22.9-9.9-4.2 0-8.7 1.2-13.4 3.7-4.7 2.4-10.4 6.7-17 12.9l-4-3.1c5-7.9 11-14.9 18-21s14.1-10.8 21.3-14.2c7.2-3.3 13.8-5 19.6-5 9.4.1 17.6 3.4 24.4 9.9zM193 167.7c.5 4.2 2.7 8.3 6.6 12 3.9 3.8 8.6 6.2 14.2 7.3.9-3.1 1.4-6.1 1.4-8.7 0-5-1.4-9.5-4.1-13.4-2.8-3.9-6-6.4-9.8-7.3-4.6 3.8-7.3 7.1-8.3 10.1zM344.9 233.5l-10.1 27.6-5 4.2-22.6 2.4c-.2-15.4-.9-34.2-2.2-56.5-1.3-22.2-2.8-42-4.4-59.3 4.4-5.3 9.4-10.5 14.9-15.3 5.5-4.9 10.5-8.5 14.9-10.8.8 3.1 1.2 8.1 1.2 14.9.2 12.1-.6 28.3-2.4 48.7-1.7 20.4-3.3 34.5-4.7 42.3l19.1-2.1 1.3 3.9z" />
            <path d="m166.6 199.8-10.1 27.6-5 4.2-9.4.9c-2 6.4-4.5 11.5-7.3 15.1-5.8 6.3-13.7 11.7-23.7 16.2s-20.1 6.7-30.3 6.7c-10.5 0-20-2.5-28.4-7.5-8.4-5-13.5-11.2-15.2-18.6 1.4-8.5 6-19.2 13.7-32.2 7.7-13 16-23.3 24.8-31l3.5 20.3c-4.2 3.1-8.6 6.8-13 11.1-4.4 4.2-7.9 8-10.4 11.3 2.5 3.3 6.7 5.9 12.5 7.9s12.8 2.9 21 2.9c8.5 0 16.2-1 23.2-2.9 7-2 12.9-4.5 17.8-7.7-.9-9.4-3-21.1-6.2-34.9-3.2-13.8-6.5-25.5-9.8-35.1 3.8-6.6 8.2-13 13.2-19.1 5-6.1 9.7-10.9 14.2-14.4 1.6 4.6 3.1 14.7 4.5 30.5s2.2 31.3 2.4 46.6l16.7-1.9 1.3 4z" />
        </svg>
    );
};

export default AferSvg;
