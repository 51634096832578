import React from "react";
import Table from "../../../components/ui/Table";
import {
    isMultiYear,
    isRegisterationFatherJob,
    isRegisterationGender,
} from "../../../services/defaultSettings";
import { printFullDate } from "../../../utils/time";

import { getYearPlaceHolder } from "../../../services/yearSevices";

const ShowUser = ({ user }) => {
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
        },
        {
            name: "عدد مرات تغير الباسورد",
            reorder: true,
            selector: (row) => row.password_reset_count,
        },
        {
            name: "الاسم الكامل",
            reorder: true,
            selector: (row) => row.full_name,
        },
    ];
    if (isRegisterationGender) {
        columns = [
            ...columns,
            {
                name: "النوع",
                reorder: true,
                selector: (row) =>
                    row.gender == "male"
                        ? "ذكر"
                        : row.gender == "female"
                        ? "انثى"
                        : "لم يتم الاختيار",
                sortable: true,
                sortField: "gender",
            },
        ];
    }
    columns = [
        ...columns,
        {
            name: "رقم الهاتف",
            reorder: true,
            selector: (row) => row.phone,
        },
        {
            name: "رقم هاتف ولي الأمر",
            reorder: true,
            selector: (row) => row.father_phone,
        },
    ];
    if (isRegisterationFatherJob) {
        columns = [
            ...columns,
            {
                name: "وظيفة ولي الأمر",
                reorder: true,
                selector: (row) => row.father_job,
                sortable: true,
                sortField: "father_job",
            },
        ];
    }
    columns = [
        ...columns,
        {
            name: "البريد الإلكتروني",
            reorder: true,
            selector: (row) => row.email,
        },
    ];
    if (isMultiYear) {
        columns.push({
            name: "العام الدراسي",
            reorder: true,
            selector: (row) => row.year,
            format: (row) => getYearPlaceHolder(row.year),
        });
    }
    columns.push({
        name: "تاريخ انشاء الحساب",
        reorder: true,
        selector: (row) => row.created_at,
        format: (row) => printFullDate(row.created_at),
    });
    return <Table columns={columns} data={user} />;
};

export default ShowUser;
