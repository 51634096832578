import React from "react";

import logoTextDark from "../assets/navbar/logodark.png";
import logoTextLight from "../assets/navbar/logolight.png";
import NavbarLink from "../layout/navbar/components/NavbarLink";
import DisclosureLink from "../layout/navbar/components/DisclosureLink";
import CenterIcon from "../components/ui/CenterIcon";

export const navLogoComponent = (darkmode) => (
    <>
        {/* <img
            className="h-12 w-auto block lg:hidden"
            src={logo}
            alt="Workflow"
        /> */}
        <img
            className="h-10 sm:h-14 w-auto md:mr-0 mr-6"
            src={darkmode ? logoTextDark : logoTextLight}
            alt="Workflow"
        />
    </>
);
export const navbarClassName = (isNavbarHidden, isRTL, scrolling) =>
    ` ${scrolling && "bg-primary-container"}`;
export const NavbarRegisterComponent = () => (
    <NavbarLink
        defualtHover="hover:bg-opacity-0 dark:hover:bg-opacity-0 hover:text-sky-800 dark:hover:text-sky-500 group"
        to="/register"
        className="bg-sky-800 border-sky-800 dark:bg-sky-500 dark:border-sky-500 clr-white border-2  group"
    >
        <CenterIcon
            icon="ph:hand-fist-duotone"
            className="text-sky-100 group-hover:text-sky-800 dark:group-hover:text-sky-500 font-h2 smooth"
        />
        <span className="flex-center-both">انشئ حسابك !</span>
    </NavbarLink>
);
export const NavbarLoginComponent = ({ isRTL }) => (
    <NavbarLink
        to="/login"
        className="border border-yellow-400 border-opacity-0 text-yellow-500"
        defualtHover="hover:dark:border-opacity-100  hover-shadow"
    >
        <CenterIcon icon="uim:rocket" className="text-yellow-600 font-h2" />
        <span
            className={`flex-center-both space-x-1 space-x-reverse ${
                !isRTL ? "flex-row-reverse" : ""
            }`}
        >
            <span>تسجيل</span>
            <span className="text-yellow-600 dark:text-yellow-500 smooth">الدخول</span>
        </span>
    </NavbarLink>
);
export const DisclosureLoginComponent = ({ isRTL }) => (
    <DisclosureLink to="/login">
        <CenterIcon icon="uim:rocket" className="text-yellow-500 h1" />
        <span className="flex-center-both">الدخول إلى حسابك</span>
    </DisclosureLink>
);
export const DisclosureRegisterComponent = () => (
    <DisclosureLink to="/register">
        <CenterIcon icon="ph:hand-fist-duotone" className="text-sky-500 h1" />
        {/* <UserAddIcon className="h-6 w-6 text-yellow-300" /> */}
        <span className="flex-center-both">انشئ حسابك الآن !</span>
    </DisclosureLink>
);

export const domainName = "mahmoud-magdy.com";
