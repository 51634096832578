import React, { useEffect, useState } from "react";

import { ScrollingProvider } from "../../../context/ScrollingContext";

import SectionHead from "../../../components/ui/SectionHead";

// import year1 from "../../../../assets/year-2.jpeg";
// import Waves from "../../../components/ui/Waves";
import Categorie from "./Categorie";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import LoadingIcon from "./../../../components/ui/LoadingIcon";
import CenterIcon from "../../../components/ui/CenterIcon";

const CoursesSection = ({ title = false }) => {
    // const { token } = useContext(AuthContext);

    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getCategories = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const { data } = await http.get("/api/categories_info", config);
        setCategories(data);
        setIsLoading(false);
    };

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <div className="relative mb-20 overflow-hidden" id="courses">
            <ScrollingProvider>
                <SectionHead title={title ? title : "الشهور"} />
            </ScrollingProvider>

            {categories.length > 0 ? (
                categories.map((c) =>
                    c.courses.length ? (
                        <Categorie
                            key={c.id}
                            id={c.id}
                            count={15}
                            titleFirst={c.titleFirst}
                            titleLast={c.titleLast}
                        />
                    ) : null
                )
            ) : isLoading ? (
                <div className="text-center">
                    <LoadingIcon className="text-4xl text-blue-700 mt-20 mb-5" /> <br />
                    <span className="text-xl">جاري تحميل الشهور</span>
                </div>
            ) : (
                <div className="text-center">
                    <CenterIcon
                        icon={"raphael:no"}
                        className="text-yellow-500 mt-20 mb-5 text-4xl"
                    />{" "}
                    <br />
                    <span className="text-xl">سيتم اضافة الشهور قريبًا</span>
                </div>
            )}
            {/* <Categorie titleFirst="كل" titleLast="الشهور" api={`/api/sellables`} /> */}

            {/* <Waves className="fill-rose-200" /> */}
            {/* <div className="py-16 bg-rose-200"></div> */}
        </div>
    );
};

export default CoursesSection;
