import React, { useContext, useEffect, useState } from "react";

import profile from "../../assets/Einstein.png";
import Container from "../../components/ui/Container";
import FlexRowReverse from "../../components/ui/FlexRowReverse";

import bggeo from "../../assets/bggeo.png";
import AferSvg from "./AferSvg";
import Waves from "../../components/ui/Waves";
import ScrollingContext from "../../context/ScrollingContext";

const AboutSection = () => {
    const { scrolling } = useContext(ScrollingContext);

    const [animatingPercentage, setAnimatingPercentage] = useState(0);

    useEffect(() => {
        const aboutSection = document.getElementById("AferWrapper");

        let aboutSectionHeight = aboutSection.clientHeight;

        let aboutSectionOffsetTop =
            aboutSection.offsetTop +
            aboutSection.offsetParent.offsetTop +
            aboutSection.offsetParent.offsetParent.offsetTop;

        let windowHeight = window.innerHeight;

        let startPoint = aboutSectionOffsetTop - windowHeight;
        let endPoint = aboutSectionOffsetTop + aboutSectionHeight / 2 - (windowHeight * 2) / 3;

        // console.log(scrolling, startPoint, endPoint);

        let percentage = (scrolling - startPoint) / (endPoint - startPoint);

        // console.log(scrolling, startPoint, endPoint);
        // console.log(percentage);
        setAnimatingPercentage(percentage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrolling]);

    return (
        <>
            <section className="relative py-20" id="about_section">
                <div
                    className="inset-0 w-full h-full z-0 absolute smooth opacity-10 invert-0 dark:invert dark:opacity-15"
                    style={{
                        backgroundImage: "url(" + bggeo + ")",
                        backgroundSize: "contain",
                        // backgroundPosition: "center top",
                        backgroundRepeat: "repeat-x",
                    }}
                ></div>
                {/* <div className="font-raka font-big text-center">عافر حلمك يستاهل</div> */}
                <div className="flex-center-both px-5 relative z-10" id="AferWrapper">
                    <div className="md:w-2/5 w-4/5">
                        <AferSvg percentage={animatingPercentage} />
                    </div>
                </div>
                <Waves className="fill-primary-container transform absolute w-full bottom-0 scale-y-[.5] translate-y-1/4" />
            </section>
            {/* <div className="relative z-10 shadow-large py-10 bg-cyan-500 saturate-50"></div> */}
            <div className="py-5"></div>
            {/* <section className="home__counter py-5">
                <Container className="py-5">
                    <div className="home-counter__container flex font-h1 flex-center-both flex-col  py-5">
                        <div className="home-counter__first">كسبنا ثقة اكثر من</div>
                        <div className="home-counter__middle font-w-bold font-bigmain px-4 text-rose-500">
                            <span className="thous">500</span>,
                            <span className="hunderds font-bigmax">000</span> +
                        </div>
                        <div className="home-counter__last">طالب</div>
                    </div>
                </Container>
            </section> */}
        </>
    );
};

export default AboutSection;
