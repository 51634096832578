import {
    adminPath,
    isBtns,
    isCodes,
    isCouponable,
    isLoginTokens,
    isMultiAdmin,
    isPlatformSubscription,
    isPrepaidSystem,
    isTimedManualSubscription,
    profileStatistics,
} from "./defaultSettings";

let list = [];
// if (adminPath === "khaled-admin") {
//     list = [
//         ...list,
//         {
//             title: "اضافة احصائيات الطالب",
//             text: "اضافة احصائيات الطالب في ملف المستخدم الخاص بالطالب",
//             icon: "add",
//             date: "09-dec-2022",
//         },
//     ];
// }
// list = [
//     ...list,
//     {
//         title: "مشاهدات الطالب",
//         text: "ظهور عدد مشاهدات الطالب للفيديو و مؤشر لمدة مشاهدته للفيديو في صفحة الكورس الخاصة بالطالب",
//         icon: "add",
//         date: "17-dec-2022",
//     },
// ];

if (false) {
    list = [
        ...list,
        {
            title: "اضافة اكتر من ادمن",
            text: "القدرة علي اضافة اكثر من ادمن و اعطائهم المسئوليات المرغوبة",
            icon: "add",
            date: "24-feb-2023",
        },
    ];
}

export const updateReleases = list;
